import ApplicationController from './application_controller'
import TurndownService from 'turndown';
import { default as InlineEditor } from '@ckeditor/ckeditor5-build-inline';

const turndownService = new TurndownService({
  headingStyle: 'atx',
  codeBlockStyle: 'fenced',
  fence: '```'
});

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default class extends ApplicationController {
  static targets = ["content", "save"]

  connect() {
    super.connect()

    const debouncedStimulate = debounce((htmlContent) => {
      const clipboard = this.element.querySelector('.clipboard');
      const markdownContent = turndownService.turndown(htmlContent);
      clipboard.innerHTML = markdownContent;
      this.stimulate('MessageReflex#update_content', this.element, markdownContent)

      // flash the whole message
      this.element.style = "background: gray";
      setTimeout(() => this.element.style = "", 300);
    }, 1000);

    this.initCkEditor(debouncedStimulate);

    // Fixes styling issues by adding the class back
    this.contentTarget.classList.add('markdown')
  }

  initCkEditor(debouncedStimulate) {
    // Don't initialize the editor if content is not editable
    if (!this.contentTarget.attributes.contenteditable) return

    InlineEditor
      .create(this.contentTarget, {
        ui: { viewportOffset: { top: 60 } },
        removePlugins: ["Table", "EasyImage", "ImageUpload", "MediaEmbed"],
        toolbar: {
          removeItems: ["uploadImage", "insertTable", "mediaEmbed", "outdent", "indent"]
        },
      })
      .then((editor) => this.setupCkEditor(editor, debouncedStimulate))
  }

  setupCkEditor(editor, debouncedStimulate) {
    editor.model.document.on("change:data", () => debouncedStimulate(editor.getData()))

    // This prevents our scripts from failing if Plausible is blocked by an adblocker
    import('../src/plausible').then(({ autoOutboundTracking }) => {
      // Plausible follows links even though they're editable. Here's a workaround for it.
      editor.editing.view.document.on("focus", () => autoOutboundTracking.cleanup())
      editor.editing.view.document.on("blur", () => autoOutboundTracking.install())
    })
  }
}
