import ApplicationController from './application_controller'

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default class extends ApplicationController {
  static targets = ['input', 'spinner']

  connect() {
    super.connect()

    const debouncedStimulate = debounce(() => {
      this.spinnerTarget.querySelector('svg').classList.add('animate-spin');
      this.stimulate(this.element.dataset.rfx, this.element, this.inputTarget.value);
    }, 1000);

    // Register the bound method as an event listener
    this.inputTarget.addEventListener("input", () => {
      debouncedStimulate();
    });
  }

}
