import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["slider", "sliderContainer", "next", "prev", "slide"];

    connect() {
        this.currentIndex = 0;
        this.slides = this.slideTargets.length;
        this.slideTargets.forEach((slide, index) => {
            slide.addEventListener("click", () => {
                this.currentIndex = index;
                this.updateSlider();
            });
        })
        this.slidePercentage = window.innerWidth > 640 ? window.innerWidth > 1024 ? 33 : 50 : 100;
        this.slidesInView = window.innerWidth > 640 ? window.innerWidth > 1024 ? 3 : 2 : 1;
        this.sliderContainerTarget.style.width = `${(this.slides * 100/this.slidesInView)}%`;
    }

    nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.slides;
        this.updateSlider();
    }

    prevSlide() {
        this.currentIndex = (this.currentIndex - 1 + this.slides) % this.slides;
        this.updateSlider();
    }

    updateSlider() {
        if (this.slides <= this.slidesInView) return
        this.sliderContainerTarget.style.transform = `translateX(-${this.currentIndex * 100/this.slides}%)`;
    }
}
