import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ["banner"]

    connect() {
      super.connect()
    }

    accept() {
        this.bannerTarget.classList.add("hidden")
        document.cookie = 'cookies_accepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
    }
}
