import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'viewer',
    'image',
    'downloadButton'
  ]

  open(event) {
    if (this.imageTarget.src != event.currentTarget.dataset.viewerUrl) {
      this.imageTarget.src = event.currentTarget.dataset.viewerUrl
      this.downloadButtonTarget.href = event.currentTarget.dataset.downloadUrl
    }
    this.viewerTarget.classList.remove('hidden')
  }

  download(event) {
    event.stopPropagation()
  }

  close() {
    this.viewerTarget.classList.add('hidden')
  }
}
