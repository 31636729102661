import ApplicationController from './application_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {

  connect() {
    super.connect()
    useClickOutside(this)
    // call clickOutside on escape keypress
    this.closeOnEscape = (event) => {
      if (event.key === "Escape") {
        this.close()
      }
    }
    document.addEventListener('keydown', this.closeOnEscape)
  }

  disconnect() {
    // remove clickOutside listener
    document.removeEventListener('keydown', this.closeOnEscape)
  }

  clickOutside() {
    this.close()
  }

  close() {
    this.element.querySelector(".email-recipient-menu").classList.add("hidden")
  }

  open() {
    this.element.querySelector(".email-recipient-menu").classList.remove("hidden")
  }

  toggle() {
    this.element.querySelector(".email-recipient-menu").classList.toggle("hidden")
  }

  toggleEmailRecipientMenu(event) {
    this.element.querySelector(".email-recipient-menu").classList.toggle("hidden")
  }
}
