import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default class extends Controller {
  connect() {
    console.log("Piechart", this.element);
    var ctx = this.element.getContext('2d');
    var myPieChart = new Chart(ctx, { type: 'pie', data: JSON.parse(this.element.dataset.values) });
  }
}
