import { DirectUpload, start } from "@rails/activestorage"

export function uploadedDocumentUrl(filename, signedId) {
  return `${window.location.href}/document_uploads/new?filename=${filename}&signed_id=${signedId}`
}

export class Uploader {
  constructor(url, blob, progressCallback = null) {
    this.url = url
    this.blob = blob
    this.progressCallback = progressCallback

    start()
  }

  upload() {
    const upload = new DirectUpload(this.blob, this.url, this)

    return new Promise((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) return reject(error)

        resolve(blob)
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    if (!this.progressCallback) return

    request.upload.addEventListener('progress', event => {
      const progress = (event.loaded / event.total) * 100
      this.progressCallback(Math.round(progress))
    })
  }
}
