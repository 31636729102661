import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()

    this.element.addEventListener("change", () => {
      document.querySelector('.outbound_email .spinner').classList.remove('hidden');
      this.stimulate('OutboundEmail#from', this.element);
    });
  }
}
