import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()

    this.element.querySelectorAll("[data-reflex]").forEach(element => {
      element.addEventListener("click", () => this.addLoadingIndicator(element))
    })
  }

  addLoadingIndicator(container) {
    container.disabled = true
    container.classList.add("disabled", "animate-pulse", "cursor-wait")
  }
}
