import ApplicationController from './application_controller'
import TurndownService from 'turndown';

const turndownService = new TurndownService({
  headingStyle: 'atx',
  codeBlockStyle: 'fenced',
  fence: '```'
});

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default class extends ApplicationController {
  static targets = ["content"]

  connect() {
    super.connect()
    const debouncedStimulate = debounce((htmlContent) => {
      const markdownContent = turndownService.turndown(htmlContent);
      this.stimulate('OutboundEmailReflex#update_body', this.element, markdownContent);
    }, 1000);  // Debounce time in milliseconds

    this.contentTarget.addEventListener("input", () => {
      const htmlContent = this.contentTarget.innerHTML;
      debouncedStimulate(htmlContent);
    });
  }
}
