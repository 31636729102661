import ApplicationController from './application_controller'
import TurndownService from 'turndown';

const turndownService = new TurndownService({
  headingStyle: 'atx',
  codeBlockStyle: 'fenced',
  fence: '```'
});

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default class extends ApplicationController {
  static targets = ["content"]

  connect() {
    super.connect();

    setTimeout(() => {
      // Checks if contentTarget exists
      try {
        this.contentTarget
      } catch {
        return
      }

      const debouncedStimulate = debounce((htmlContent) => {
        const clipboard = this.element.querySelector('.clipboard');
        const markdownContent = turndownService.turndown(htmlContent);
        clipboard.innerHTML = markdownContent;
        this.stimulate('Message#update_content', this.element, markdownContent);
      }, 1000);  // Debounce time in milliseconds

      this.contentTarget.addEventListener("dblclick", () => {
        this.contentTarget.contentEditable = true;
        this.contentTarget.focus();
      });

      this.contentTarget.addEventListener("blur", () => {
        this.contentTarget.contentEditable = false;
      });

      this.contentTarget.addEventListener("input", () => {
        const htmlContent = this.contentTarget.innerHTML;
        debouncedStimulate(htmlContent);
      });
    }, 1000);
  }
}
