import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    "confirmDestroy",
    "destroy",
    "cancelDestroy",
  ]

  onInput() {
    this.element.dataset.dirty = true
  }

  update() {
    if (this.element.dataset.dirty !== "true") return

    const content = this.element.textContent
    this.stimulate("Thought#update", this.element, { content })

    this.element.dataset.dirty = false
  }

  confirmDestroy() {
    this.confirmDestroyTarget.classList.add("hidden")
    this.destroyTarget.classList.remove("hidden")
    this.cancelDestroyTarget.classList.remove("hidden")
  }

  destroy() {
    this.destroyTarget.classList.add("hidden")
    this.cancelDestroyTarget.classList.add("hidden")

    this.stimulate("Thought#destroy")
    this.element.remove()
  }

  cancelDestroy() {
    this.confirmDestroyTarget.classList.remove("hidden")
    this.destroyTarget.classList.add("hidden")
    this.cancelDestroyTarget.classList.add("hidden")
  }
}
