import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { percent: Number }

  connect() {
    this.setGaugeProgress(this.percentValue);
  }

  setGaugeProgress(percent) {
    const circle = this.element.querySelector('.gauge-ring__circle');
    const radius = circle.r.baseVal.value;
    const circumference = 2 * Math.PI * radius;
    // Calculate the offset to start at 7 o'clock and sweep to 4 o'clock
    const fullSweep = circumference * 0.8; // 270 degrees
    const offset = circumference * 0.25 + fullSweep - (percent / 100 * fullSweep);

    circle.style.strokeDasharray = `${fullSweep} ${circumference}`;
    circle.style.strokeDashoffset = offset;

    // Update the stroke color based on the percent
    if (percent >= 25) {
      circle.style.stroke = 'green';
    } else if (percent >= 10) {
      circle.style.stroke = 'yellow';
    } else {
      circle.style.stroke = 'red';
    }
  }
}
