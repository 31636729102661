import ApplicationController from './application_controller'
import TurndownService from 'turndown';

const turndownService = new TurndownService({
  headingStyle: 'atx',
  codeBlockStyle: 'fenced',
  fence: '```'
});

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default class extends ApplicationController {

  static targets = ["name", "email", "autosend", "notes", "trashcan"]

  connect() {
    super.connect()

    this.nameTarget.addEventListener("change", () => {
      this.stimulate('AddressBook#update', this.element, 'name', this.nameTarget.value);
    });

    this.emailTarget.addEventListener("change", () => {
      this.stimulate('AddressBook#update', this.element, 'email', this.emailTarget.value);
    });

    this.autosendTarget.addEventListener("change", () => {
      this.stimulate('AddressBook#update', this.element, 'auto_send', this.autosendTarget.checked);
    });

    const debouncedStimulate = debounce((htmlContent) => {
      const markdownContent = turndownService.turndown(htmlContent);
      this.stimulate('AddressBook#update', this.element, 'notes', markdownContent);
    }, 1000);  // Debounce time in milliseconds

    this.notesTarget.addEventListener("input", () => {
      const htmlContent = this.notesTarget.innerHTML;
      debouncedStimulate(htmlContent);
    });

    this.trashcanTarget.addEventListener("click", () => {
      // confirm using native JS dialog
      if (confirm("Are you sure you want to delete this address?")) {
        this.stimulate('AddressBook#destroy', this.element);
      }
    });
  }
}
