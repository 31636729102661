import ApplicationController from './application_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ["menu", "reactions", "reacted"]

  connect() {
    super.connect()
    useClickOutside(this)
    // call clickOutside on escape keypress
    this.closeOnEscape = (event) => {
      if (event.key === "Escape") {
        this.close()
      }
    }
    document.addEventListener('keydown', this.closeOnEscape)
  }

  disconnect() {
    // remove clickOutside listener
    document.removeEventListener('keydown', this.closeOnEscape)
  }

  clickOutside() {
    this.close()
    // add hidden class to reactions menu but only if it exists
    try {
      this.reactionsTarget.classList.add("hidden")
    }
    catch(err) {
      // do nothing
    }
  }

  close() {
    this.menuTarget.classList.add("hidden")
  }

  open() {
    this.menuTarget.classList.remove("hidden")
  }

  toggle() {
    this.menuTarget.classList.toggle("hidden")
  }

  toggleEmailRecipientMenu(event) {
    this.element.querySelector(".email-recipient-menu").classList.toggle("hidden")
  }

  toggleReactions(event) {
    event.preventDefault();
    this.reactionsTarget.classList.toggle("hidden")
  }

  reacting(event) {
    event.preventDefault();
    const emoji = event.target.dataset.emoji
    this.stimulate('MessageReflex#react', this.element, emoji)
  }

  unreacting(event) {
    event.preventDefault();
    this.stimulate('MessageReflex#unreact', this.element)
  }

  destroy(event) {
    this.element.remove()
    this.stimulate('MessageReflex#destroy', event.currentTarget)
  }
}
