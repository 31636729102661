import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['prompter']

  connect() {
    super.connect()
    console.log('Hello from CloneControl')
  }

  clear(event) {
    // clear the textarea where the event was dispatched
    event.target.value = ''
  }
}
