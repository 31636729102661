import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['prompter']

  connect() {
    super.connect()
    console.log('Hello from CloneSpecController')
  }

  prompt() {
    this.stimulate('Clone#prompt', this.element, this.prompterTarget.value)
  }
}
