import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    index: { type: Number, default: 0},
    enterguard: Boolean,
  }
  static targets = ['list','autocompletion','textarea','emojipicker']

  connect() {
    super.connect()
    this.boundPleaseWaitMethod = this.handlePleaseWait.bind(this)
    document.addEventListener('pleaseWait', this.boundPleaseWaitMethod)

    const storeSignedIds = localStorage.getItem(this.storageKeyForSignedIds())

    this.uploadedImageSignedIds = storeSignedIds || []
  }

  handleUploaded(event) {
    this.uploadedImageSignedIds = event.detail.signedIds

    if (event.detail.storeSignedIds) {
      localStorage.setItem(this.storageKeyForSignedIds(), this.uploadedImageSignedIds)
    }
  }

  handlePleaseWait(event) {
    console.log("handlePleaseWait(event)", event, event.detail, this.element.dataset.id, this.textareaTarget)
    if(event.detail.conversationId == this.element.dataset.id) {
      alert("Please wait for assistant to finish typing its response.")
      this.textareaTarget.innerText = event.detail.message
    }
  }

  input() {
    this.showOrHideList();
    this.filterAutocompletions()
    this.revealFilteredAutocompletions()
    this.resetIndex()
    this.highlightIndex()
  }

  showOrHideList() {
    if (this.textareaTarget.innerText[0] === '/') {
      this.listTarget.classList.remove('hidden')
    } else {
      this.listTarget.classList.add('hidden')
    }
  }

  filterAutocompletions() {
    this.filteredAutocompletions = this.autocompletionTargets.filter(e => e.dataset.name.startsWith(this.textareaTarget.innerText))
  }

  revealFilteredAutocompletions() {
    this.autocompletionTargets.forEach(e => e.classList.add('hidden'))
    this.filteredAutocompletions.forEach(e => e.classList.remove('hidden'))
  }

  highlightIndex() {
    this.autocompletionTargets.forEach(e => e.classList.remove('bg-slate-400', 'text-slate-700'))
    this.autocompletionTargets.forEach(e => e.classList.add('text-slate-500'))
    if (this.filteredAutocompletions[this.index]) {
      this.filteredAutocompletions[this.index].classList.remove('text-slate-500')
      this.filteredAutocompletions[this.index].classList.add('bg-slate-400', 'text-slate-700')
    }
  }

  resetIndex() {
    this.filteredAutocompletions.length < this.autocompletionTargets.length ? this.index = 0 : this.index = undefined
  }

  keydown(event) {
    const isSlashCommand = /^\/\w+$/.test(this.textareaTarget.innerText)
    const isEnter = event.key === 'Enter'
    const isShift = event.shiftKey
    const isModifiedEnter = (event.metaKey && isEnter) || (event.ctrlKey && isEnter) // work for both mac and windows

    // let shift+enter just be itself, no matter what
    if (isShift && isEnter) return

    // hit enter to submit highlighted slash command (don't require command/enter in EnterGuard mode)
    if (isEnter && isSlashCommand) return this.submitHighlightedSelection(event)

    // hit enter to submit while not in EnterGuard mode
    if (isEnter && !isSlashCommand && !this.enterguardValue) return this.prompt(event)

    // hit modified-enter to submit prompt in EnterGuard mode
    if (isModifiedEnter && this.enterguardValue) return this.prompt(event)

    if (event.key === 'Tab' && isSlashCommand) return this.completeHighlightedSelection()
    if (event.key === 'ArrowRight' && isSlashCommand) return this.completeHighlightedSelection()
    if (event.key === 'ArrowUp' && isSlashCommand) return this.decrementIndex()
    if (event.key === 'ArrowDown' && isSlashCommand) return this.incrementIndex()
    if (event.key === 'Escape') return this.textareaTarget.blur()
  }

  decrementIndex() {
    if (this.index === undefined) this.index = this.filteredAutocompletions.length
    this.index = Math.max(0, this.index - 1)
    this.highlightIndex()
  }

  incrementIndex() {
    if (this.index === undefined) this.index = -1
    this.index = Math.min(this.filteredAutocompletions.length - 1, this.index + 1)
    this.highlightIndex()
  }

  completeHighlightedSelection() {
    const autocompletion = this.filteredAutocompletions[this.index]
    if (!autocompletion) return

    this.textareaTarget.innerText = autocompletion.dataset.name
    this.blur()
  }

  submitHighlightedSelection(event) {
    this.completeHighlightedSelection()
    this.prompt(event)
  }

  draft(event) {
    const prompt = this.textareaTarget.innerText.trim()
    console.log('saving draft', prompt)
    this.stimulate('ConversationReflex#draft_prompt', prompt)
  }

  prompt(event) {
    event.preventDefault();
    const prompt = this.textareaTarget.innerText.trim()
    if (prompt.length === 0) return; // prevent empty prompts

    if (prompt.length > 20000) { // prevent too long prompts
      alert("Your prompt is too long, please shorten it. (ProPlus members may upload large documents directly.)")
      return;
    }

    console.log('prompting', prompt)

    if (prompt === "/clear" || prompt === "/delete") {
      const command = prompt.split("/")[1]
      if (!window.confirm(this.element.dataset[`confirm${command}`])) {
        this.textareaTarget.innerText = ""
        return
      }

      if (command === "clear") {
        document.querySelector(".messages").innerHTML = ""
        this.textareaTarget.innerText = ""
      } else {
        this.dispatchCStatusEvent(this.element.dataset.deletingMessage)
        this.disable()
      }
    } else {
      this.textareaTarget.innerText = ""
    }

    this.stimulate(
      'ConversationReflex#prompt',
      this.textareaTarget,
      {
        prompt,
        images: this.uploadedImageSignedIds,
      }
    )

    this.uploadedImageSignedIds = []
    this.dispatch("clearUploaded")
  }

  clickOption(event) {
    this.textareaTarget.innerText = event.currentTarget.dataset.name
    this.prompt(event)
    this.blur()
  }

  blur() {
    setTimeout(() => {
      this.listTarget.classList.add('hidden');
    }, 100);
  }

  disable() {
    this.textareaTarget.disabled = true
    this.textareaTarget.contentEditable = false
    this.textareaTarget.classList.add("text-slate-400", "disabled")
  }

  storageKeyForSignedIds() {
    return `prompter(${this.element.dataset.id})#imageSignedIds`
  }
}
