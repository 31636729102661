import { Application } from "@hotwired/stimulus"
import consumer from "../channels/consumer"
import Notification from 'stimulus-notification'
import ReadMore from 'stimulus-read-more'

const application = Application.start()
application.register('notification', Notification)
application.register('read-more', ReadMore)

// Configure Stimulus development experience
application.debug    = false
application.consumer = consumer
window.Stimulus      = application

export { application }
