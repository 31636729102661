
import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default class extends Controller {
  connect() {
    console.log("StackedBarChart", this.element);
    const ctx = this.element.getContext('2d');
    const chartData = JSON.parse(this.element.dataset.values);

    const myStackedBarChart = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Function Calls Distribution Over a Month'
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              // autoSkip: true,
              // maxRotation: 0, // Prevent rotation
              // minRotation: 0, // Prevent rotation
              // callback: function(value, index, values) {
              //   // Ensure value is a string before splitting
              //   const stringValue = String(value);
              //   // Format the label to be more readable
              //   return stringValue.split('_')[0]; // Show only the date part
              // }
              display: false
            },
            title: {
              display: false,
              text: 'Date'
            }
          },
          y: {
            stacked: true,
            beginAtZero: true,
            title: {
              display: true,
              text: 'Function Calls'
            }
          }
        },
        plugins: {
          legend: {
            display: false // Hide the legend
          }
        }
      }
    });
  }
}
