import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "hideable" ]

  connect() {
    super.connect()
    useClickOutside(this)
  }

  showTargets() {
    this.hideableTargets.forEach(el => {
      el.classlist.remove("hidden")
    });
  }

  hideTargets() {
    this.hideableTargets.forEach(el => {
      el.classlist.add("hidden")
    });
  }

  toggleTargets() {
    this.hideableTargets.forEach((el) => {
      el.classList.toggle("hidden")
    });
  }

  clickOutside() {
    this.hideableTargets.forEach((el) => {
      el.classList.add("hidden")
    });
  }
}
