import ApplicationController from './application_controller'
import hljs from 'highlight.js'

export default class extends ApplicationController {
  connect() {
    super.connect()
    console.log('SyntaxHighlightController#connect', this.element);

    this.element.querySelectorAll('pre code').forEach((el) => {
      hljs.highlightElement(el);
    });

    this.element.querySelectorAll("pre").forEach((codeBlock)=>{
      const lang = codeBlock.children[0].classList[codeBlock.children[0].classList.length-1].split("-")[1]
      codeBlock.children[0].setAttribute("data-lang", lang)
    })
  }
}
