import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Cmessages controller connected!", this.element)
    this.master = !!this.element.dataset.master
  }

  update(event) {
    console.log("Cmessages controller update! 🍔", event)
    const messageId = event.detail.id
    const messageHtml = event.detail.html
    const visible = event.detail.visible

    if (visible || this.master) {
      let messageElement = this.element.querySelector(messageId)
      if (messageElement) {
        messageElement.outerHTML = messageHtml
      } else {
        this.element.insertAdjacentHTML('beforeend', messageHtml)
      }

      // Find element again since it just got inserted or replaced
      messageElement = this.element.querySelector(messageId)
      const mainElement = document.querySelector("main")
      // Scroll to the message using an offset to account for the sticky bot bar
      mainElement.scrollTo({ top: messageElement.offsetTop - 50, behavior: "smooth" })
    }
  }
}
