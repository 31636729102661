import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()
    const stimulate = this.stimulate.bind(this)

    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            stimulate('MessageReflex#mark_read', entry.target)
            observer.unobserve(entry.target)
          }
        })
      }
    )
    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.unobserve(this.element)
  }
}
