import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'uploadButton',
    'caption',
    'uploading',
    'ellipsis',
    'error',
    'errorMessage',
  ]

  connect() {
    super.connect()

    this.errored = false
    document.addEventListener("carbon:upload-started", () => this.onUploadStarted())
    document.addEventListener("carbon:upload-error", (e) => this.onUploadError(e.detail.errorMessage))
    document.addEventListener("carbon:uploaded-to-backend", (e) =>  this.onUploadedToBackend(e))
  }

  open() {
    this.dispatch("open", { target: document, detail: { open: true } })
  }

  onUploadStarted() {
    this.resetError()

    this.uploadButtonTarget.disabled = true
    this.uploadButtonTarget.classList.add("disabled")
    this.captionTarget.classList.add("hidden")
    this.uploadingTarget.classList.remove("hidden")
    this.ellipsisTarget.classList.remove("hidden")

    setTimeout(
      () => {
        if (this.errored) return

        document.dispatchEvent(new CustomEvent("carbon:upload-error", { detail: { errorMessage: "Request timeout" } }))
      },
      10000
    )
  }

  onUploadError(errorMessage) {
    this.reset()
    this.errored = true
    this.errorTarget.classList.remove("hidden")
    this.errorMessageTarget.innerText = errorMessage
  }

  onUploadedToBackend(event) {
    const { detail: { id } } = event
    window.location = `/conversations/${window.GLOBALS.conversationId}/document_uploads/${id}/edit`
  }

  reset() {
    this.uploadButtonTarget.disabled = false
    this.uploadButtonTarget.classList.remove("disabled")
    this.captionTarget.classList.remove("hidden")
    this.uploadingTarget.classList.add("hidden")
    this.ellipsisTarget.classList.add("hidden")
  }

  resetError() {
    this.errored = false
    this.errorTarget.classList.add("hidden")
  }
}
