import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["audio", "avatar", "play", "loading", "pause"]

  connect() {
    super.connect()
    this.audioTarget.volume = 0.7;

    this.audioTarget.addEventListener("canplay", () => {
      this.loadingTarget.classList.add("hidden")
      this.pauseTarget.classList.remove("hidden")
    });

    this.audioTarget.addEventListener("ended", () => {
      this.loadingTarget.classList.add("hidden")
      this.pauseTarget.classList.add("hidden")
      this.playTarget.classList.remove("hidden")
    });
  }

  play() {
    this.audioTarget.play();
    if (this.audioTarget.readyState === 0) {
      this.playTarget.classList.add("hidden")
      this.loadingTarget.classList.remove("hidden")
    } else {
      this.playTarget.classList.add("hidden")
      this.pauseTarget.classList.remove("hidden")
    }
  }

  pause() {
    this.audioTarget.pause();
    this.playTarget.classList.remove("hidden")
    this.pauseTarget.classList.add("hidden")
    this.loadingTarget.classList.add("hidden")
  }
}
