import ApplicationController from './application_controller'

export default class extends ApplicationController {
  destroy() {
    if (!confirm(this.element.dataset.confirmDelete)) return

    this.stimulate('Conversation#destroy', this.element)

    this.element.remove()
    this.dispatchCStatusEvent(this.element.dataset.deletingMessage)
  }
}
