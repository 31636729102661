import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'header',
    'menu',
    'footer',
    'menuIcon',
    'closeIcon',
  ]

  static values = {
    hideHeader: Boolean,
  }

  toggleTargets() {
    if (this.hideHeaderValue) {
      this.headerTarget.classList.toggle('hidden')
    }
    this.menuTarget.classList.toggle('hidden')
    this.footerTarget.classList.toggle('hidden')
    this.menuIconTarget.classList.toggle('hidden')
    this.closeIconTarget.classList.toggle('hidden')
  }
}
