import Cookies from 'js-cookie'
import jstz from 'jstz'

export function findTimeZone() {
  const oldIntl = window.Intl
  try {
    window.Intl = undefined
    const tz = jstz.determine().name()
    window.Intl = oldIntl
    return formatTimeZone(tz)
  } catch (e) {
    // sometimes (on android) you can't override intl
    return formatTimeZone(jstz.determine().name())
  }
}

function formatTimeZone(timeZone) {
  const parts = timeZone.split('/')
  return parts.length > 1 ? parts[1].replace(/_/g, ' ') : timeZone
}

document.addEventListener("turbo:load", () => {
  console.log("Setting browser time zone to: ", findTimeZone())
  Cookies.set("browser_time_zone", findTimeZone(), { expires: 365, path: "/", secure: true, sameSite: 'strict' })
})
