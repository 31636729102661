import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["counter", "textarea"];

  connect() {
    console.log("Character Counter connected!", this.element);
    this.updateCounter();
  }

  updateCounter() {
    const characterCount = this.textareaTarget.value.length;
    // grab the limit from the maxlength attribute
    const limit = this.textareaTarget.getAttribute("maxlength");
    this.counterTarget.textContent = characterCount;
    if (characterCount > (limit * 0.9)) {
      this.counterTarget.classList.add('text-red-500'); // Assuming you're using TailwindCSS for utility classes
    } else {
      this.counterTarget.classList.remove('text-red-500');
    }
  }
}
