import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Cstatus controller connected!", this.element)

    // Bind the method to this controller instance once
    this.boundEventMethod = this.handleCustomEvent.bind(this)

    // Register the bound method as an event listener
    document.addEventListener('cStatus', this.boundEventMethod)
  }

  disconnect() {
    // Remove the same function that was added as a listener
    document.removeEventListener('cStatus', this.boundEventMethod)
  }

  handleCustomEvent(event) {
    console.log("handleCustomEvent(event)", event, event.detail.conversationId, this.element.dataset.id)
    if(event.detail.conversationId == this.element.dataset.id) {
      this.element.textContent = event.detail.message
    }
  }
}
