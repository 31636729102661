import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "../channels"
import "../controllers"
import "../src"
import "../config"
import * as ActiveStorage from "@rails/activestorage"
import * as ActionCable from '@rails/actioncable'
import LocalTime from "local-time"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

Turbo.setFormMode("off")
ActionCable.logger.enabled = true
LocalTime.start()
ActiveStorage.start()

// Vite appears to bundle code in such a way that
// makes Rails UJS autostart itself. This only happens
// in production, so explicitly starting is required
// in dev envs.
if (!window._rails_loaded) {
  Rails.start()
}
