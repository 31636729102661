import { Uploader } from "../src/uploader"
import ApplicationController from './application_controller'

const MAX_IMAGE_AMOUNT = 4

export default class extends ApplicationController {
  static targets = ['fileinput', 'imagelist', 'preview']
  static values = {
    conversationId: String,
    imagesEnabled: Boolean,
    documentsEnabled: Boolean,
  }

  connect() {
    super.connect()

    this.uploadedImageSignedIds = []
  }

  openFileInput() {
    this.fileinputTarget.click()
  }

  onDragover(e) {
    e.preventDefault()
  }

  clearUploaded() {
    this.uploadedImageSignedIds = []
    this.imagelistTarget.querySelectorAll('.preview:not(.hidden)').forEach(p => p.remove())
  }

  // Allows for regular pasting if text is present and no files are
  async handlePaste(event) {
    const text = event.clipboardData.getData("text")
    const files = event.clipboardData.files

    if (!text && files.length > 0)  {
      this.attachFilesToConversation(event)
    }
  }

  async attachFilesToConversation(event) {
    event.preventDefault()

    let files = [...event.dataTransfer?.files || event.currentTarget?.files || event.clipboardData?.files || []]

    // Resets file input
    if (event.currentTarget) {
      event.currentTarget.value = ""
    }

    const documents = files.filter(file => !file.type.match(/image|video|audio.*/))
    if (this.documentsEnabledValue && documents.length > 0) {
      this.uploadDocument(documents)
      return // Don't upload images if the user is trying to upload documents
    }

    if (this.imagesEnabledValue) {
      const images = files.filter(file => file.type.match(/image.*/))
      this.uploadImages(images)
    }
  }

  async uploadDocument(documents) {
    const file = documents[0] // Only 1 document can be uploaded at a time
    if (!file) return

    const uploadedDocument = await this.uploadFile(file, (progress) => {
      this.dispatch('cStatus', {
        prefix: null,
        target: document,
        detail: { conversationId: this.conversationIdValue, message: `Uploading ${file.name}... (${progress}%)` }
      })
    })

    window.location.href = this.uploadedDocumentUrl(file.name, uploadedDocument.signed_id)
  }

  uploadedDocumentUrl(filename, signedId) {
    return `${window.location.href}/document_uploads/new?filename=${filename}&signed_id=${signedId}`
  }

  async uploadImages(images) {
    // Only take up to a limit amount of images
    const files = images.slice(0, this.getMaxImageAmount())

    const uploadPromises = files.map(async (blob) => {
      const previewElement = this.createImagePreview(blob)
      const progressElement = previewElement.querySelector('.progress')

      const uploadedImage = await this.uploadFile(blob, (progress) => {
        progressElement.style.width = `${progress}%`

        if (progress >= 100) {
          progressElement.classList.add('hidden')
        }
      })

      previewElement.dataset.signedId = uploadedImage.signed_id
      this.uploadedImageSignedIds.push(uploadedImage.signed_id)

      this.updatePrompter()
    })

    await Promise.all(uploadPromises)
  }

  getMaxImageAmount() {
    if (this.uploadedImageSignedIds.length >= MAX_IMAGE_AMOUNT) return 0

    return MAX_IMAGE_AMOUNT - this.uploadedImageSignedIds.length
  }

  createImagePreview(file) {
    const clone = this.previewTarget.cloneNode(true)

    clone.classList.remove('hidden')
    clone.querySelector('img').src = URL.createObjectURL(file)
    clone.querySelector('.delete-button').addEventListener('click', (e) => this.deleteImage(e))

    this.imagelistTarget.appendChild(clone)

    return clone
  }

  async uploadFile(file, progressCallback) {
    try {
      const uploader = new Uploader(this.fileinputTarget.dataset.directUploadUrl, file, progressCallback)
      return await uploader.upload()
    } catch (error) {
      console.log(error)
    }
  }

  deleteImage(e) {
    const element = e.currentTarget.parentElement
    const signedId = element.dataset.signedId

    element.remove()
    this.uploadedImageSignedIds = this.uploadedImageSignedIds.filter(id => id != signedId)
    this.updatePrompter()
  }

  updatePrompter() {
    this.dispatch('uploaded', { detail: { signedIds: this.uploadedImageSignedIds } })
  }
}
