import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    console.log("Cnav controller connected!", this.element)
    // bind to the page up button
    document.addEventListener("keydown", (event) => {
      if (event.key === "PageUp") {
        this.previous()
      }
    }
    )
    // bind to the page down button
    document.addEventListener("keydown", (event) => {
      if (event.key === "PageDown") {
        this.next()
      }
    }
    )
  }

  previous() {
    console.log("previous")
    this.stimulate('Conversation#previous', this.element)
  }

  next() {
    console.log("next")
    this.stimulate('Conversation#next', this.element)
  }
}
