import 'chartjs-adapter-date-fns';

import { Controller } from "@hotwired/stimulus";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default class extends Controller {
  connect() {
    const jsonData = JSON.parse(document.getElementById(this.element.dataset.datasource).textContent);
    const strategies = ['dau', 'conversations', 'messages', 'subscribers', 'tokens'];

    const dataByStrategy = {};
    strategies.forEach(s => {
      dataByStrategy[s] = jsonData.filter(item => item.strategy_name === s)
                                   .map(item => ({x: item.start_date, y: item.value}));
    });

    const palette = [
      'rgba(255, 99, 132, 1)',  // Red
      'rgba(75, 192, 192, 1)',  // Teal
      'rgba(255, 206, 86, 1)',  // Yellow
      'rgba(153, 102, 255, 1)', // Purple
      'rgba(54, 162, 235, 1)'   // Blue
    ];

    const datasets = strategies.map((s, index) => {
      const color = palette[index % palette.length];
      return {
        label: s,
        yAxisID: (s === 'tokens' || s === 'messages') ? 'y2' : 'y1',
        data: dataByStrategy[s],
        fill: false,
        borderColor: color,
        pointBorderColor: color,  // Fill the point border with the color
        pointBackgroundColor: color,  // Fill the point with the color
        borderWidth: 1
      }
    });

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day'
          }
        },
        'y1': {
          type: 'linear',
          position: 'left'
        },
        'y2': {
          type: 'linear',
          position: 'right'
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true  // Fill the legend boxes
          }
        }
      }
    };

    const ctx = this.element.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: { datasets },
      options: options
    });
  }
}
